
  import VueSlickCarousel from 'vue-slick-carousel'
  import { mapState } from 'vuex'

  export default {
    data() {
      return  {
        activeSlide: 0,
        playing: 'none',
        settings: {
          // autoplaySpeed: 6500,
          // autoplay: true,
          swipeToSlide: true,
          arrows: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          accessibility: true,
        },
      }
    },
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },
    components: {
      VueSlickCarousel,
    },
    beforeMount() {
      if(!this.homepageCarouselContentCards) this.$braze.requestContentCardsRefresh()
    },
    mounted() {
      this.$nextTick(() => {
        if (this.isVideo(this.slides[0])) {
          this.play(0)
        }
      })
      console.log("Slides", this.slides)
    },
    computed: {
      ...mapState(['user', 'homepageCarouselContentCards']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      contentCards() {
        let _content_cards = []
        _content_cards.push(...this.homepageCarouselContentCards)
        return _content_cards
      },
      slides(){
        // TODO - Update this to include logic to insert the cards into the native slides using priority logic for placement
        let _slides = []
        if(this.contentCards.length) {
          _slides.push(...this.blok.slides)
          this.contentCards.forEach((card) => {
            _slides.push({
              chip: card.extras["chip"] ?? "",
              caption: card.extras["caption"] ?? "",
              lockup: {
                alt: card.extras["lockup_text"] ?? "",
                filename: card.extras["lockup"] ?? ""
              },
              lockup_text: card.extras["lockup_text"] ?? "",
              button: [
                {
                  url: { cached_url : card.url ?? "" },
                  text: card.linkText ?? ""
                }
              ],
              background_mobile: {
                alt: "",
                filename: card.extras["background_mobile"] ?? ""
              },
              background_desktop: {
                alt: "",
                filename: card.extras["background_desktop"] ?? ""
              }
            })
          })
          return _slides
        }
        else {
          return this.blok.slides
        }
      }
    },
    methods: {
      background(slide) {
        if (this.mobile && slide.background_mobile?.filename) {
          return slide.background_mobile.filename
        } else {
          return slide.background_desktop.filename
        }
      },
      width(slide) { // width of lockup if set
        let width
        if (this.mobile && slide.lockup_width_mobile) {
          width = slide.lockup_width_mobile
        } else if (!this.mobile && slide.lockup_width_desktop) {
          width = slide.lockup_width_desktop
        }
        if (width) {
          return 'width:'+width+'px'
        } else {
          return null
        }
      },
      next() {
        this.$refs.carousel.next()
      },
      prev() {
        this.$refs.carousel.prev()
      },
      goToSlide(i) {
        this.$refs.carousel.goTo(i)
      },
      change(e,i) {
        // Pause all videos and set to beginning
        const videos = document.querySelectorAll('.homepage-slider video')
        for (let n = 0; n < videos.length; n++) {
          videos[n].pause()
          videos[n].currentTime = 0
        }
        this.playing = 'none'
        if (i !== this.activeSlide) {
          this.activeSlide = i
          // Play video if slide is video slide
          if (this.isVideo(this.slides[i])) {
            this.play(i)
          }
        }
      },
      isVideo(slide) {
        const filename = this.background(slide)
        return filename.includes('.mp4')
      },
      pause(i) {
        const video = document.querySelector('[data-video="'+i+'"]')
        if (video) {
          video.pause()
          this.playing = 'none'
        }
      },
      play(i) {
        this.$nextTick(() => {
          const video = document.querySelector('[data-video="'+i+'"]')
          if (video) {
            video.play()
            this.playing = i
          }
        })
      },
      handleDotClick(slide,i) {
        if (this.isVideo(slide) && this.activeSlide == i) {
          if (this.playing == i) {
            this.pause(i)
          } else {
            this.play(i)
          }
        } else {
          this.goToSlide(i)
        }
      }
    }
  }
